import React, { useState } from 'react';
import { BrowserRouter, Switch, } from 'react-router-dom';
import ReactGA from 'react-ga';

import { AuthProvider } from './context/auth';
import { UIProvider } from './context/ui';
import GuestRoute from './libs/GuestRoute';
import globalLinks from './config/links';
import Splash from './libs/Splash';
import { UIContextProps, UIContextState } from './types/shared';
import globalSizes from './config/sizes';
import { useAuth } from './hooks/auth';
import useDevice from './hooks/device';
import { AuthContextProps } from './context/auth';
import ErrorBoundaryWrapper from './libs/ErrorBoundaryWrapper';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const AuthRoutes = React.lazy(() => import('./components/AuthRoutes'));

ReactGA.initialize(globalSizes.googleAnalytics);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [device] = useDevice();
  const [ui, setUi] = useState<UIContextState>({
    menuState: false,
    device,
  });
  const [auth, setAuth] = useAuth();
  const setValue = (newValue: Partial<Omit<AuthContextProps, "signOut" | "signIn">>) => {
    setAuth({
      ...auth,
      ...newValue
    });
  };
  const authProviderValue = {
    ...auth,
    setValue,
    signOut: () => setAuth({ ...auth, isLoggedin: false }),
    signIn: () => setAuth({ ...auth, isLoggedin: true }),
  };
  const uiProviderValue: UIContextProps = {
    menuState: ui.menuState,
    device,
    setMenuState(newState: boolean) {
      setUi({
        ...ui,
        menuState: newState,
      })
    },
  }
  if (auth.loading) {
    return <Splash />
  }
  return (
    <React.Suspense fallback={<Splash />}>
      <ErrorBoundaryWrapper
        className="d-flex justify-content-center align-items-center page__bloc bg-white"
        fallback={<ErrorBoundaryFallback />}
      >
        <AuthProvider value={authProviderValue}>
          <UIProvider value={uiProviderValue}>
            <BrowserRouter>
              <Switch>
                <GuestRoute auth={auth.isLoggedin} path={[globalLinks.signin, globalLinks.signup, globalLinks.resetPasswordAction, globalLinks.resetPasswordRequest, globalLinks.accountValidation]}>
                  <AuthRoutes isLoggedin={auth.isLoggedin} />
                </GuestRoute>
                <Dashboard auth={auth.isLoggedin} />
              </Switch>
            </BrowserRouter>
          </UIProvider>
        </AuthProvider>
      </ErrorBoundaryWrapper>
    </React.Suspense>
  );
}

export default App;
