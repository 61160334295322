import React from 'react';
import { AlertTriangle } from 'react-feather';

import NotificationAlert from '../NotificationAlert';
import globalSizes from '../../config/sizes';
import GradientButton from '../GradientButton';

export default function ErrorBoundaryFallback() {

  const onRefresh = () => {
    window.location.reload();
  }

  return (
    <NotificationAlert
      mainTitle='Something went wrong'
      icon={<AlertTriangle size={globalSizes.icon.default} />}
    >
      <GradientButton onClick={onRefresh} className='text-white'>
        Actualisez
      </GradientButton>
      {` or contact nous `}
      <a href={`mailto://${globalSizes.supportMail}`}>par email</a>.
    </NotificationAlert>
  )
}
