import React from 'react';

import SpinnerLogo from '../../components/SpinnerLogo';

import './style.css';

export default function Splash() {
  return (
    <div className={`splash__bloc`}>
      <picture className={`splash-picture__container`}>
        <SpinnerLogo className='splash-picture__image' />
      </picture>
    </div>
  )
}
