const statusColor: any = {
  PENDING: 'warning',
  DELIVRED: 'success',
  PAID: 'success',
  CANCEL: 'danger',
}
const globalSizes = {
  icon: {
    default: 20,
    xm: 35,
    md: 50,
    xl: 80,
    xxl: 160,
  },
  netAmountFees: .6,
  dates: {
    fr: {
      format: 'dd/MM/yyyy',
      monthFormat: 'dd MMMM yyyy',
      whole: 'hh:mm dd MMMM yyyy',
      month: 'MMMM yyyy',
      day: 'EEEE dd MMMM yyyy'
    }
  },
  cover: {
    format: ['image/png', 'image/jpg', 'image/jpeg'],
  },
  audio: {
    format: ['audio/wav', 'audio/x-wav'],
  },
  stripeClientToken: 'pk_live_51HXvkFEl65vF8rsiWzfcCTEIEbFL3gjF8GkGiip584dchRnUvhBwibETN0m5wG5GoS32vx7IjbDoMnSDoX0fLC6a00FfPfv1ps',
  paidStatus: 'PAID',
  deliveredStatus: 'DELIVRED',
  statusColor,
  googleAnalytics: "UA-177819551-2",
  currency: 'USD',
  MAX_FILE_CHUNK_SIZE: 350000, // 350Kb
  recaptcha: {
    key: "6Lf4Q2UaAAAAABVGmGsr8hcaU65NaQ3Wr9qpSR2y",
    language: 'fr',
    scriptProps: {
      appendTo: 'body',
      defer: true
    }
  },
  supportMail: `Support@Dropzik.com`
}

export default globalSizes;