import React from 'react';
import { KeyValueObject } from '../../types/shared';

import './style.css';

export default function SpinnerLogo({ className }: KeyValueObject<string>) {
  return (
    <svg
      className={`animated-svg ${className}`}
      viewBox="0 0 185.000000 251.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,251.000000) scale(0.100000,-0.100000)"
        fill="transparent" stroke="none">
        <path d="M852 2454 c-18 -10 -44 -31 -56 -48 -71 -95 -664 -1030 -692 -1092
              -108 -233 -104 -536 11 -765 105 -209 277 -368 487 -449 326 -126 712 -46 957
              199 277 277 348 695 177 1041 -18 36 -102 173 -187 305 l-154 240 -5 -540 c-5
              -533 -5 -541 -28 -596 -49 -121 -134 -209 -258 -266 -78 -36 -206 -44 -300
              -18 -222 60 -374 295 -336 518 45 261 293 436 542 384 41 -8 83 -18 93 -22 16
              -7 17 19 17 452 0 378 -3 468 -15 510 -17 58 -49 108 -87 136 -37 28 -122 33
              -166 11z"/>
        <path d="M819 1265 c-68 -22 -139 -74 -181 -135 -21 -29 -38 -58 -38 -64 0 -6
              15 12 34 39 45 66 80 98 146 132 48 25 66 28 145 28 78 0 97 -4 146 -28 67
              -32 142 -109 172 -175 16 -36 21 -67 22 -132 0 -77 -3 -92 -33 -152 -51 -105
              -149 -177 -263 -193 -55 -8 -148 9 -202 37 -49 25 -119 94 -146 144 -11 22
              -20 35 -21 31 -1 -20 42 -86 82 -124 158 -153 414 -126 537 58 47 70 65 140
              59 224 -11 125 -83 232 -196 287 -55 27 -80 33 -142 35 -45 2 -94 -3 -121 -12z"/>
        <path d="M872 1214 c-25 -17 -76 -110 -64 -117 4 -3 28 -8 55 -12 l47 -7 0 76
              c0 42 -3 76 -8 76 -4 0 -18 -7 -30 -16z m28 -59 l0 -66 -37 7 c-47 8 -46 8
              -38 34 6 20 62 90 71 90 2 0 4 -29 4 -65z"/>
        <path d="M940 1154 l0 -77 52 7 c29 4 54 9 56 11 7 6 -26 71 -52 103 -45 52
              -56 44 -56 -44z m49 35 c21 -23 46 -77 39 -84 -2 -2 -20 -6 -39 -7 l-34 -3 -3
              58 c-3 62 5 70 37 36z"/>
        <path d="M769 1192 c-24 -16 -50 -36 -57 -44 -10 -13 -8 -17 18 -28 17 -7 33
              -11 35 -8 9 9 54 102 51 105 -1 1 -22 -10 -47 -25z m6 -41 c-13 -27 -19 -30
              -35 -21 -11 6 -20 12 -20 14 0 6 64 45 68 41 2 -2 -4 -17 -13 -34z"/>
        <path d="M1056 1168 c14 -29 28 -55 30 -57 5 -6 64 20 64 28 0 9 -46 43 -88
              65 l-33 17 27 -53z m45 1 c34 -24 36 -35 10 -42 -14 -3 -23 4 -35 29 -18 40
              -15 41 25 13z"/>
        <path d="M650 1065 c-12 -25 -24 -63 -27 -85 l-6 -40 61 0 60 0 6 48 c4 26 9
              55 12 64 4 12 -6 23 -35 38 -22 11 -42 20 -45 20 -2 0 -14 -20 -26 -45z m65
              13 c26 -12 29 -26 19 -90 -7 -38 -7 -38 -56 -38 l-50 0 7 36 c7 42 35 104 46
              104 5 0 20 -5 34 -12z"/>
        <path d="M1135 1092 c-16 -10 -32 -18 -33 -19 -2 -1 -1 -31 3 -67 l7 -66 59 0
              59 0 0 33 c0 34 -43 138 -57 136 -4 0 -21 -8 -38 -17z m61 -39 c9 -20 18 -52
              21 -70 l6 -33 -46 0 c-25 0 -48 3 -50 8 -3 4 -8 30 -12 58 -6 46 -4 51 17 61
              38 19 48 15 64 -24z"/>
        <path d="M787 1038 c-4 -12 -7 -40 -7 -60 l0 -38 65 0 65 0 0 55 c0 54 -1 55
              -29 55 -16 0 -42 3 -58 6 -26 5 -31 2 -36 -18z m101 -4 c9 -2 12 -17 10 -41
              l-3 -38 -52 -3 c-52 -3 -53 -3 -53 25 0 60 9 72 48 66 21 -2 43 -7 50 -9z"/>
        <path d="M985 1051 l-40 -6 0 -50 0 -50 65 0 65 0 -3 35 c-6 85 -5 84 -87 71z
              m73 -55 l3 -47 -53 3 -53 3 0 40 c0 39 1 40 40 46 58 9 60 7 63 -45z"/>
        <path d="M624 869 c7 -46 42 -119 56 -119 26 0 80 35 76 49 -2 9 -7 37 -11 64
              l-6 47 -61 0 -61 0 7 -41z m110 0 c13 -65 8 -84 -24 -96 -35 -13 -43 -6 -65
              57 -23 66 -21 70 34 70 46 0 49 -1 55 -31z"/>
        <path d="M780 873 c0 -62 9 -83 34 -78 11 2 38 7 59 10 37 6 37 6 37 55 l0 50
              -65 0 -65 0 0 -37z m120 -12 c0 -41 -13 -51 -71 -51 -29 0 -39 15 -39 61 0 29
              1 29 55 29 l55 0 0 -39z"/>
        <path d="M940 860 c0 -49 0 -49 38 -55 20 -3 47 -8 59 -11 24 -5 25 -2 38 79
              l6 37 -71 0 -70 0 0 -50z m120 1 c0 -49 -9 -55 -66 -46 -42 6 -44 8 -44 39 0
              43 4 46 62 46 l48 0 0 -39z"/>
        <path d="M1110 891 c0 -10 -3 -39 -6 -64 -7 -46 -6 -46 31 -64 37 -18 37 -17
              56 5 20 25 39 83 39 120 0 20 -4 22 -60 22 -52 0 -60 -3 -60 -19z m110 -12 c0
              -11 -10 -43 -21 -70 -17 -38 -26 -48 -38 -43 -9 3 -24 9 -35 12 -15 4 -17 11
              -12 41 3 20 6 46 6 59 0 20 5 22 50 22 44 0 50 -3 50 -21z"/>
        <path d="M818 763 c-16 -4 -17 -7 -2 -41 15 -37 70 -102 86 -102 4 0 8 34 8
              75 l0 75 -37 -1 c-21 -1 -46 -3 -55 -6z m82 -63 c0 -33 -4 -60 -8 -60 -13 0
              -37 30 -57 69 -19 38 -11 51 38 51 26 0 27 -1 27 -60z"/>
        <path d="M940 696 c0 -42 3 -76 8 -76 4 0 18 7 30 16 24 16 77 112 68 121 -3
              3 -28 8 -56 11 l-50 5 0 -77z m77 58 c22 -6 22 -7 7 -38 -18 -35 -53 -76 -66
              -76 -4 0 -8 27 -8 60 0 56 2 60 23 60 12 0 32 -3 44 -6z"/>
        <path d="M731 730 l-34 -17 34 -25 c38 -28 79 -51 83 -46 2 2 -9 26 -23 54
              l-27 52 -33 -18z m44 -31 c9 -17 15 -32 13 -34 -2 -2 -18 8 -37 22 -25 19 -29
              28 -19 34 21 14 27 11 43 -22z"/>
        <path d="M1058 690 c-26 -50 -26 -51 -5 -44 26 8 69 37 86 58 10 12 9 17 -8
              25 -34 19 -45 12 -73 -39z m62 30 c13 -8 12 -11 -5 -25 -30 -23 -58 -38 -52
              -27 4 5 11 21 16 36 11 28 18 31 41 16z"/>
      </g>
    </svg>
  )
}
