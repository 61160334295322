import React from 'react';
import Card, { CardProps, } from 'react-bootstrap/Card';

import './style.css';

interface NotificationAlertProps extends CardProps {
  mainTitle: string;
  titleClassName?: string;
  icon: React.ReactNode;
}

export default function NotificationAlert({ mainTitle, titleClassName = '', border = 'dark', icon, className, children, ...rest }: NotificationAlertProps) {
  return (
    <Card border={border} className={`alert__block ${className}`} {...rest}>
      <Card.Body>
        <Card.Title className={`alert__title ${titleClassName}`}>
          {icon}
          <span className='ml-1'>{mainTitle}</span>
        </Card.Title>
        <div className="card-text alert__text">
          {children}
        </div>
      </Card.Body>
    </Card>
  )
}
