import { createContext } from 'react';
import { UIContextProps } from '../types/shared';


const UIProviderInitValue: UIContextProps = {
  menuState: false,
  device: {
    os: 'unknown',
    isMobile: false,
  },
  setMenuState: (ns: boolean) => {},
}

const UIContext = createContext<UIContextProps>(UIProviderInitValue);

export const UIProvider = UIContext.Provider;

export default UIContext;