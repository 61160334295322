import { useEffect, useState } from "react";
import { DeviceI } from "../types/device";
import { getOSDetails } from "../utils/agent";

/**
 * retrieve user device details such as OS and if using a mobile or not
 */
const useDevice = () => {
  const [device, setDevice] = useState<DeviceI>({
    os: "unknown",
    isMobile: false,
  });
  const mobileOS = ['android', 'ios', 'windows-phone'];

  useEffect(() => {
    const os = getOSDetails();
    let _device: DeviceI;

    if (mobileOS.includes(os)) {
      _device = {
        os,
        isMobile: true,
      };
    } else {
      _device = {
        os,
        isMobile: false,
      };
    }

    setDevice(_device);
  }, []);

  return [device] as const;
}

export default useDevice;