const globalLinks = {
  signin: '/signin',
  signup: '/signup',
  resetPasswordRequest: '/reset-password',
  accountValidation: '/account-validation/:token',
  resetPasswordAction: '/reset-password-action',
  home: '/',
  tracks: '/tracks',
  releases: '/releases',
  reporting: {
    root: '/reporting',
    byTrack: '/reporting/tracks',
    youtube: '/reporting/youtube',
  },
  account: {
    root: '/account',
    profile: '/account/profile',
    payment: '/account/payment',
    billing: {
      main: '/account/billing',
      bankAccount: '/account/billing/new-bank-account',
      paypal: '/account/billing/new-paypal-account',
    },
    invoices: '/account/invoices',
    invoice: '/account/invoice',
    payouts: '/account/payouts',
  },
  library: {
    root: '/library',
    releases: '/library/releases',
    release: '/library/release',
    editRelease: '/library/release/:id/edit',
    tracks: '/library/tracks',
    artists: '/library/artists',
    contributors: '/library/contributors',
    editors: '/library/editors',
    newRelease: {
      details: '/release/new',
      tracks: '/release/new/:id/tracks',
      plan: '/release/new/:id/plan',
      summary: '/release/new/:id/summary',
      pay: '/release/new/:id/pay',
    }
  },
  services: {
    root: '/services',
    smartlink: '/services/fenan-pro',
    subscriptions: '/services/subscriptions',
  },
  stats: {
    root: '/stats'
  },
  tools: {
    root: '/tools/support-tickets',
  },
  api: {
    authChecking: '/api/auth/check',
    signup: '/api/auth/signup',
    signin: '/api/auth/signin',
    resetPasswordRequest: '/api/auth/reset_password/request',
    accountValidation: '/api/auth/account-validation',
    resetPasswordAction: '/api/auth/reset_password/action',
    signout: '/api/auth/signout',
    account: {
      profile: '/api/account/profile',
      bankAccount: '/api/account/new-bank-account',
      paypalAccount: '/api/account/new-paypal-account',
      billingAccounts: '/api/account/billing-accounts',
      billingAccount: '/api/account/billing-account',
      invoices: '/api/account/invoices/mylist',
      invoice: '/api/account/invoice',
      payouts: '/api/account/payouts',
      payoutRequest: '/api/account/payout/request',
    },
    libraries: {
      releasesList: '/api/library/mylist',
      create: '/api/library/create',
      release: '/api/library',
      delivery: '/api/library/delivery',
      artists: {
        mylist: '/api/artist/mylist',
        edit: '/api/artist',
        create: '/api/artist/create',
      },
      contributors: {
        mylist: '/api/contributor/mylist',
        edit: '/api/contributor',
        create: '/api/contributor/create',
      },
      musicians: {
        mylist: '/api/musician/mylist',
        edit: '/api/musician',
        create: '/api/musician/create',
      },
      editors: {
        mylist: '/api/editor/mylist',
        edit: '/api/editor',
      },
      tracks: {
        mylist: '/api/track/mylist',
        edit: '/api/track',
        create: '/api/track/create',
      },
      orders: {
        root: '/api/order',
        clientSecret: '/pay/start',
      }
    },
    services: {
      coupon: '/promo-code',
      smartlink: {
        create: '/api/services/smartlink/create',
        deleteOrEdit: '/api/services/smartlink/library',
        mylist: '/api/services/smartlink/mylist',
      },
      subscription: {
        create: '/api/services/subscription/create'
      },
    },
    reporting: {
      all: '/api/track/reports',
      youtube: '/api/report/youtube/all'
    },
    stats: {
      global: '/api/stats/global',
    },
    tools: {
      ticket: {
        create: '/api/tools/ticket/create',
        list: '/api/tools/ticket/list',
      }
    }
  },
  conditions: 'https://www.dropzik.com/contrat-de-licence/',
  terms: 'https://www.dropzik.com/conditions-generales-dutilisation/',
  fenanPro: 'fenan.pro',
  mobileStores: {
    appStore: 'https://apps.apple.com/eg/app/dropzik-mon-compte/id1581826409',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.dropzik.app'
  },
}

export default globalLinks;