import React, { ErrorInfo } from 'react';

interface ErrorBoundaryWrapperStateType {
  hasError: boolean
}
interface ErrorBoundaryWrapperPropsType {
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
  fallback?: React.ReactNode | null;
  componentDidCatch?: (error: Error, info: ErrorInfo) => void;
}

export default class ErrorBoundaryWrapper extends React.Component<ErrorBoundaryWrapperPropsType, ErrorBoundaryWrapperStateType> {
  constructor(props: ErrorBoundaryWrapperPropsType) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true
    });
    this.props?.componentDidCatch && this.props.componentDidCatch(error, errorInfo);
  }

  render() {
    if (this.state.hasError === false) {
      return (
        this.props.children
      );
    } else {
      return (
        <div style={this.props.style} className={this.props.className}>
          {
            this.props.fallback
          }
        </div>
      );
    }
  }
}
