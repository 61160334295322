import { useState, useEffect } from 'react';
import Axios from 'axios';

import { KeyValueObject } from '../types/shared';
import globalLinks from '../config/links';

interface AuthState {
  loading: boolean;
  isLoggedin: boolean;
  csrf: string;
  cash: number;
  currency: string;
  invoices: boolean;
  location: string;
  reports: KeyValueObject<any>[],
  isLabel?: boolean;
  dueDate?: number;
  errors?: any;
}

/**
 * load all required details of user account such as:
 * unfinished invoices, currency, region("eu" for europe /..)
 */
export function useAuth() {
  const [auth, setAuth] = useState<AuthState>({
    loading: true,
    isLoggedin: false,
    csrf: '',
    cash: 0,
    currency: 'USD',
    invoices: false,
    location: 'am',
    reports: [],
    errors: null,
  });
  const source = Axios.CancelToken.source();

  useEffect(() => {
    const checkAuthState = async () => {
      let isLoggedin;
      let csrf = '';
      let location = 'am';
      let invoices = false;
      let cash = 0;
      let currency = 'USD';
      let reports = [];
      let isLabel;
      let dueDate;
      let errors = null;

      try {
        const { data } = await Axios.get(globalLinks.api.authChecking, {
          cancelToken: source.token,
        });

        if (data.code === 'success') {
          isLoggedin = true;
          location = data.data?.location;
          invoices = data.data?.invoices;
          cash = data.data?.cash;
          currency = data.data?.currency;
          reports = data.data?.reports;
          isLabel = data.data?.isLabel;
          dueDate = data.data?.dueDate;
        } else {
          isLoggedin = false;
        }
        csrf = data.csrf;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          csrf = error.response.data.csrf;
          errors = error.response.data.errors;
        }
        isLoggedin = false;
      }
      let result = {
        ...auth,
        csrf,
        cash,
        currency,
        reports,
        invoices,
        location,
        loading: false,
        isLoggedin,
        isLabel,
        errors,
      };
      result = dueDate
        ? { ...result, dueDate }
        : result;

      setAuth(result);
    }
    checkAuthState();
    return () => {
      source.cancel();
    }
  }, []);

  return [auth, setAuth] as const;
}