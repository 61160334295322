import { OST } from "../../types/device";

/**
 * Get the Operating System used by client
 * @returns OS name
 */
export function getOSDetails(): OST {
  const userAgent = navigator.userAgent;

  if (/windows phone/i.test(userAgent)) {
    return "windows-phone";
  } else if (/windows/i.test(userAgent)) {
    return "windows";
  } else if (/android/i.test(userAgent)) {
    return "android";
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "ios";
  } else if (/Macintosh/i.test(userAgent)) {
    return "macos";
  }

  return "unknown";
}